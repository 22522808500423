import { Block, Category, CatToolbox, CustomCategory, Input, Separator, Variable } from "./blockly_setup";

const blockMutations = {};
window.blm = blockMutations;
export function registerBlockMutationForToolbox(block, idname, mutation) {
    if (blockMutations[block]) {
        blockMutations[block][idname] = mutation;
    } else {
        blockMutations[block] = {
            [idname]: mutation,
        };
    }
};

export function getBlockMutations(block) {
    return blockMutations[block] || {};
}

export function getBlockMutation(block, idname) {
    if (!blockMutations[block] || !blockMutations[block][idname]) {
        throw new Error(`Block mutation ${block}:${idname} not found`);
    }
    return blockMutations[block][idname];
}

export const providedCategories = {
    "logic": Category("Logic", "#5b80a5", [
        Block("controls_if", {}, {}),
        Block("logic_compare", { OP: "EQ" }, {}),
        Block("logic_operation", { OP: "AND" }, {}),
        Block("logic_negate", {}, {}),
        Block("logic_boolean", { BOOL: "TRUE" }, {}),
        Block("logic_null", {}, {}),
        Block("logic_ternary", {}, {}),
    ]),
    "controls": Category("Controls", "#5ba55b", [
        Block("controls_repeat_ext", {}, { TIMES: Input(Block("math_number", { NUM: 10 }, {})) }),
        Block("controls_whileUntil", { MODE: "WHILE" }, {}),
        Block("controls_for", {
            VAR: Variable("i"),
        }, {
            FROM: Input(Block("math_number", { NUM: 1 }, {})),
            TO: Input(Block("math_number", { NUM: 10 }, {})),
            BY: Input(Block("math_number", { NUM: 1 }, {})),
        }),
        Block("controls_forEach", {
            VAR: Variable("j"),
        }, {}),
        Block("controls_flow_statements", { FLOW: "BREAK" }, {}),
    ]),
    "math": Category("Math", "#5b67a5", [
        Block("math_number", { NUM: 0 }, {}),
        Block("math_arithmetic", { OP: "ADD" }, {
            A: Input(Block("math_number", { NUM: 1 }, {})),
            B: Input(Block("math_number", { NUM: 1 }, {})),
        }),
        Block("math_single", { OP: "ROOT" }, {
            NUM: Input(Block("math_number", { NUM: 9 }, {})),
        }),
        Block("math_trig", { OP: "SIN" }, {
            NUM: Input(Block("math_number", { NUM: 45 }, {})),
        }),
        Block("math_constant", { CONSTANT: "PI" }, {}),
        Block("math_number_property", {
            PROPERTY: "EVEN"
        }, { NUMBER_TO_CHECK: Input(Block("math_number", { NUM: 0 }, {})) }, "<mutation divisor_input=\"false\"></mutation>"),
        Block("math_round", { OP: "ROUND" }, { NUM: Input(Block("math_number", { NUM: 3.1 }, {})) }),
        Block("math_on_list", { OP: "SUM" }, {}, "<mutation op=\"SUM\"></mutation>"),
        Block("math_modulo", {}, {
            DIVIDEND: Input(Block("math_number", { NUM: 64 }, {})),
            DIVISOR: Input(Block("math_number", { NUM: 10 }, {})),
        },),
        Block("math_constrain", {}, {
            VALUE: Input(Block("math_number", { NUM: 50 }, {})),
            LOW: Input(Block("math_number", { NUM: 1 }, {})),
            HIGH: Input(Block("math_number", { NUM: 100 }, {})),
        }),
        Block("math_random_int", {}, {
            FROM: Input(Block("math_number", { NUM: 1 }, {})),
            TO: Input(Block("math_number", { NUM: 100 }, {})),
        }),
        Block("math_random_float", {}, {}),
    ]),
    "text": Category("Text", "#5ba58c", [
        Block("text", { TEXT: "" }, {}),
        Block("text_join", {}, {}, {
            "itemCount": 2,
        }),
        Block("text_append", {
            VAR: Variable("item"),
        }, {
            TEXT: Input(Block("text", { TEXT: "" }, {})),
        }),
        Block("text_length", {
            VALUE: Input(Block("text", { TEXT: "" }, {})),
        }),
        Block("text_isEmpty", {}, {
            VALUE: Input(Block("text", { TEXT: "" }, {})),
        }),
        Block("text_indexOf", {
            END: "FIRST",
        }, {
            VALUE: Input(null, Block("variables_get", { VAR: Variable("text") })),
            FIND: Input(Block("text", { TEXT: "abc" }, {})),
        }),
        Block("text_charAt", { WHERE: "FROM_START" }, {
            VALUE: Input(null, Block("variables_get", {
                VAR: Variable("text"),
            }))
        }, "<mutation at=\"true\"></mutation>"),
        Block("text_getSubstring", { WHERE1: "FROM_START", WHERE2: "FROM_START" }, {
            STRING: Input(null, Block("variables_get", { VAR: Variable("text") }, {}))
        }, "<mutation at1=\"true\" at2=\"true\"></mutation>"),
        Block("text_changeCase", { CASE: "UPPERCASE" }, { TEXT: Input(Block("text", { TEXT: "abc" }, {})) }),
        Block("text_trim", { MODE: "BOTH" }, { TEXT: Input(Block("text", { TEXT: "abc" }, {})) }),
        Block("text_print", {}, { TEXT: Input(Block("text", { TEXT: "abc" }, {})) }),
        Block("text_prompt_ext", { TYPE: "TEXT" }, { TEXT: Input(Block("text", { TEXT: "abc" }, {})) }, "<mutation type=\"TEXT\"></mutation>"),
    ]),
    "lists": Category("Lists", "#745ba5", [
        Block("lists_create_with", {}, {}, { itemCount: 0 }),
        Block("lists_create_with", {}, {}, { itemCount: 3 }),
        Block("lists_repeat", {}, { NUM: Input(Block("math_number", { NUM: 5 }, {})) }),
        Block("lists_length", {}, {}),
        Block("lists_isEmpty", {}, {}),
        Block("lists_indexOf", { END: "FIRST" }, { VALUE: Input(null, Block("variables_get", { VAR: Variable("list") }, {})) }),
        Block("lists_getIndex", {
            MODE: "GET",
            WHERE: "FROM_START",
        }, {
            VALUE: Input(null, Block("variables_get", { VAR: Variable("list") }, {})),
        }, "<mutation statement=\"false\" at=\"true\"></mutation>"),
        Block("lists_setIndex", {
            MODE: "SET",
            WHERE: "FROM_START",
        }, {
            LIST: Input(null, Block("variables_get", { VAR: Variable("list") }, {})),
        }, "<mutation at=\"true\"></mutation>"),
        Block("lists_getSublist", {
            WHERE1: "FROM_START",
            WHERE2: "FROM_START",
        }, {
            LIST: Input(null, Block("variables_get", {
                VAR: Variable("list"),
            }, {}))
        }),
        Block("lists_split", {
            MODE: "SPLIT",
        }, {
            DELIM: Input(Block("text", { TEXT: "," }, {})),
        }, "<mutation mode=\"SPLIT\"></mutation>"),
        Block("lists_sort", {
            TYPE: "NUMERIC",
            DIRECTION: "1",
        }, {}),
    ]),
    "variables": CustomCategory("Variables", "#a55b80", "VARIABLE"),
    "procedures": CustomCategory("Procedures", "#995ba5", "PROCEDURE"),
};

export const libraryCategoriesList = [
    providedCategories.logic,
    providedCategories.controls,
    providedCategories.math,
    providedCategories.text,
    providedCategories.lists,
    Separator(),
    providedCategories.variables,
    providedCategories.procedures,
];

export function provideCategory(name, category) {
    providedCategories[name] = category;
}

export function provideCategoryFromBlockMutations(id, name, color, block_mutation_ids) {
    providedCategories[id] = Category(name, color, block_mutation_ids.map((bmid) => getBlockMutation(...bmid.split(":"))));
}

let toolbox_templates = {};

export function getRegisteredToolboxes() {
    return Object.keys(toolbox_templates);
}

export function registerToolbox(name, contents) {
    toolbox_templates[name] = contents;
}

export function hasToolbox(name) {
    return name in toolbox_templates;
}

export function getToolbox(template_name) {
    // const content = toolbox_templates[template_name];
    // let toolbox;
    // if (content[0]?.kind === "category") {
    //     toolbox = CatToolbox;
    // } else {
    //     toolbox = BloToolbox;
    // }
    return CatToolbox(
        ...toolbox_templates[template_name]
    )
}
