import { Blocks } from "blockly";
import { CatToolbox, transformToolboxBlocks } from "../blockly_setup";
import { defaultWorkspace } from "../defaults";
import { getAllCodes, registerCode } from "./codes_registry";
import { registerToolboxCode } from "./utils";



registerToolboxCode("tlib", "library");
registerToolboxCode("tno", "off");
registerToolboxCode("teasy", "easy");

registerCode("CLEAN", function ({ displayMessage, setJson, workspace }) {
    setJson(defaultWorkspace);
    displayMessage("success", `Cleaned workspace`);
});

registerCode("ntrsh", function ({ displayMessage, workspace }) {
    workspace.trashcan.emptyContents();
    displayMessage("success", `Emptied trashcan`);
});

registerCode("EMERG", function ({ displayMessage, setToolbox, setJson }) {
    // setJson(emptyWorkspace);
    // setToolbox(CatToolbox());
    // displayMessage("info", `Wait for reset...`);
    // setTimeout(() => {
    //     localStorage.clear();
    //     window.location.reload();
    //     displayMessage("info", `Wait for reload...`);
    // }, 2000);
    window.emerg();
});

registerCode("RESET", function ({ displayMessage, setToolbox, setJson, setShowOutput }) {
    setJson(defaultWorkspace);
    setToolbox(CatToolbox());
    displayMessage("", "");
    setShowOutput(false);
});

registerCode("hid", function ({ displayMessage, setShowControls }) {
    setShowControls(false);
    displayMessage("success", `Now hiding controls`);
});

registerCode("sho", function ({ displayMessage, setShowControls }) {
    setShowControls(true);
    displayMessage("success", `Now showing controls`);
});

registerCode("iamanidiot", function ({ displayMessage }) {
    alert(getAllCodes().join("\n"));
});

registerCode("", function ({ displayMessage }) {
    displayMessage("info", ``);
});

registerCode("shout", function ({ setShowOutput }) {
    setShowOutput(true);
});

registerCode("shin", function ({ setShowOutput }) {
    setShowOutput(false);
});

registerCode("ad-decon", function ({ setAllowDeconstruct }) {
    setAllowDeconstruct(true);
})
registerCode("ad-ndecon", function ({ setAllowDeconstruct }) {
    setAllowDeconstruct(false);
})

registerCode("tbdecon", function ({ toolbox, setToolbox }) {
    setToolbox(transformToolboxBlocks(toolbox, obj => Blocks[obj.type]?.deconstruct, obj => Blocks[obj.type].deconstruct(obj)));
})

registerCode("bknd", function ({ setBackendServer }) {
    setBackendServer(prompt("Enter backend server URL", "http://localhost:5000"));
})