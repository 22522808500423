const codes = {};

export function registerCode(id, action) {
    codes[id] = action;
}

export function getCode(id) {
    return codes[id];
}

export function getAllCodes() {
    return Object.keys(codes);
}

export function hasCode(id) {
    return id in codes;
}

export function runCode(id, ...args) {
    const code = getCode(id);
    if (!code) {
        return;
    }
    code(...args);
}