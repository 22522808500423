export function importModule(moduleName, generator) {
    return generator.provideFunction_(
        moduleName,
        [
            `import ${moduleName} as ${generator.FUNCTION_NAME_PLACEHOLDER_}`
        ]
    );
}

export function importModulePart(moduleName, modulePart, generator) {
    return generator.provideFunction_(
        modulePart,
        [
            `from ${moduleName} import ${modulePart} as ${generator.FUNCTION_NAME_PLACEHOLDER_}`
        ]
    );
}

export function defineConstant(name, value, generator) {
    return generator.provideFunction_(
        name,
        `${generator.FUNCTION_NAME_PLACEHOLDER_} = ${value}`
    );
}

export function defineFunction(name, code, generator) {
    return generator.provideFunction_(
        name,
        code
    );
}