import { FieldDropdown } from "blockly";
import { Order } from "blockly/python";
import { Category, defineBlock } from "../../blockly_setup";
import { buildBlock } from "../../toolboxManager";
import { blockBuilderAMOUNT, blockBuilderANGLE, blockBuilderPORT, blockBuilderVALUESlider, getMotor, limit } from "./utils";

// defineBlock("spike_motor_start", {
//     init: function () {
//         this.appendValueInput("PORT")
//             .appendField("Starte Motor")
//             .setCheck(["spike_PORT", "String"]);
//         this.appendValueInput("SPEED")
//             .appendField("mit")
//             .setCheck("Number");
//         this.appendDummyInput()
//             .appendField("%-iger Geschwindigkeit");
//         this.setInputsInline(true);
//         this.setPreviousStatement(true, null);
//         this.setNextStatement(true, null);
//         this.setColour("#d0593e");
//         this.setTooltip("Startet den angegebenen Motor am angegebenen Port mit der angegebenen Geschwindigkeit in %.");
//         this.setHelpUrl("");
//     }
// }, function (block, generator) {
//     const spikeMotor = getMotor(block, "PORT", generator);
//     const speed = generator.valueToCode(block, 'SPEED', Order.ATOMIC) || "75";
//     return `${spikeMotor}.start(${speed})\n`;
// });

export const block_motor_start = defineBlock("spike_motor_start", {
    init: function () {
        this.appendValueInput("PORT")
            .appendField("Starte Motor")
            .setCheck(["spike_PORT", "String"]);
        this.appendValueInput("VALUE")
            .appendField("mit")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField("%-iger")
            .appendField(new FieldDropdown([
                ["Leistung", "power"],
                ["Geschwindigkeit", "speed"],
            ]), "MODE");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("Startet den angegebenen Motor am angegebenen Port mit der angegebenen Leistung in %.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    const mode = block.getFieldValue("MODE");
    const value = limit(generator.valueToCode(block, 'VALUE', Order.ATOMIC) || "75", -100, 100, generator);
    return `${spikeMotor}.start${mode === "power" ? "_at_power" : ""}(${value})\n`;
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
    blockBuilderVALUESlider(blockData, opts);
});

defineBlock("spike_motor_stop", {
    init: function () {
        this.appendValueInput("PORT")
            .appendField("Stoppe Motor")
            .setCheck(["spike_PORT", "String"]);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("Stoppt den angegebenen Motor.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    return `${spikeMotor}.stop()\n`;
}, blockBuilderPORT)

defineBlock("spike_motor_run_to_position", {
    init: function () {
        this.appendValueInput("PORT")
            .appendField("Drehe Motor")
            .setCheck(["spike_PORT", "String"]);
        this.appendValueInput("VALUE")
            .appendField("mit")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField("%-iger Geschwindigkeit");
        this.appendDummyInput()
            .appendField("bis")
        this.appendValueInput("ANGLE")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField("°");
        this.appendDummyInput()
            .appendField(new FieldDropdown([
                ["über den kürzesten Weg", "shortest path"],
                ["im Uhrzeigersinn", "clockwise"],
                ["gegen den Uhrzeigersinn", "counterclockwise"],
            ]), "DIRECTION");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("Dreht den angegebenen Motor am angegebenen Port in der angegebenen RIchtung mit der angegebenen Geschwindigkeit in % bis zur angegebenen Position in °.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    const speed = limit(generator.valueToCode(block, 'VALUE', Order.ATOMIC) || "75", 0, 100, generator);
    const position = limit(generator.valueToCode(block, 'ANGLE', Order.ATOMIC) || "0", 0, 359, generator);
    const direction = block.getFieldValue("DIRECTION");
    return `${spikeMotor}.run_to_position(${position}, "${direction}", speed=${speed})\n`;
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    blockBuilderVALUESlider(blockData, opts);
    if (opts.direction) {
        blockData.fields.DIRECTION = opts.direction;
    }
    blockBuilderANGLE(blockData, opts);
    // blockData.inputs.ANGLE = Input(Block("spike_type_angle", { ANGLE: 0 }, {}));
})

defineBlock("spike_motor_run_for", {
    init: function () {
        this.appendValueInput("PORT")
            .appendField("Drehe Motor")
            .setCheck(["spike_PORT", "String"]);
        this.appendValueInput("VALUE")
            .appendField("mit")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField("%-iger Geschwindigkeit");
        this.appendValueInput("AMOUNT")
            .appendField("für")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField(new FieldDropdown([
                ["Sekunden", "seconds"],
                ["Grad", "degrees"],
                ["Umdrehungen", "rotations"],
            ]), "MODE");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("Dreht den angegebenen Motor am angegebenen Port mit der angegebenen Geschwindigkeit in % für die angegebene Zeit in Sekunden, Grad oder Umdrehungen.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    const speed = limit(generator.valueToCode(block, 'VALUE', Order.ATOMIC) || "75", -100, 100, generator);
    const value = generator.valueToCode(block, 'AMOUNT', Order.ATOMIC) || "0";
    const mode = block.getFieldValue("MODE");
    return `${spikeMotor}.run_for_${mode}(${value}, speed=${speed})\n`;
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    blockBuilderVALUESlider(blockData, opts);
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
    blockBuilderAMOUNT(blockData, opts);
});

defineBlock("spike_motor_run_to_degrees_counted", {
    init: function () {
        this.appendValueInput("PORT")
            .appendField("Drehe Motor")
            .setCheck(["spike_PORT", "String"]);
        this.appendValueInput("VALUE")
            .appendField("mit")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField("%-iger Geschwindigkeit");
        this.appendValueInput("AMOUNT")
            .appendField("bis")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField("Grad relativ zur Startposition");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("Dreht den angegebenen Motor am angegebenen Port mit der angegebenen Geschwindigkeit in % bis zu den angegebenen Grad relativ zur Startposition.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    const speed = limit(generator.valueToCode(block, 'VALUE', Order.ATOMIC) || "75", 0, 100, generator);
    const degrees = generator.valueToCode(block, 'AMOUNT', Order.ATOMIC) || "0";
    return `${spikeMotor}.run_to_degrees_counted(${degrees}, speed=${speed})\n`;
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    blockBuilderVALUESlider(blockData, opts);
    blockBuilderAMOUNT(blockData, opts);
});

defineBlock("spike_motor_get", {
    init: function () {
        this.appendDummyInput()
            .appendField("Gebe die")
            .appendField(new FieldDropdown([
                ["Geschwindigkeit", "speed"],
                ["Position", "position"],
                ["Grad relativ zur Startposition", "degrees_counted"],
            ]), "MODE");
        this.appendValueInput("PORT")
            .appendField("des Motors")
            .setCheck(["spike_PORT", "String"]);
        this.appendDummyInput()
            .appendField("zurück");
        this.setInputsInline(true);
        this.setOutput(true, "Number");
        this.setColour("#d0593e");
        this.setTooltip("Gibt die aktuelle Position, Geschwindigkeit oder Grad relativ zur Startposition des angegebenen Motors am angegebenen Port zurück.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    const mode = block.getFieldValue("MODE");
    return [`${spikeMotor}.get_${mode}()`, Order.FUNCTION_CALL];
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
});

defineBlock("spike_motor_set", {
    init: function () {
        this.appendDummyInput()
            .appendField("Setze")
            .appendField(new FieldDropdown([
                ["Grad relativ zur Startposition", "degrees_counted"],
                ["das Stop-Verhalten", "stop_action"],
                ["'Stoppe wenn blockiert'", "stall_detection"],
            ]), "MODE");
        this.appendValueInput("PORT")
            .appendField("des Motors")
            .setCheck(["spike_PORT", "String"]);
        this.appendValueInput("VALUE")
            .appendField("auf")
            .setCheck(null);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("Setzt die Grad relativ zur Startposition, das Stop-Verhalten oder die Blockierungs-Erkennung des angegebenen Motors am angegebenen Port auf den angegebenen Wert.");
        this.setHelpUrl("");
    },
    onchange: function (e) {
        if (e.type === "create") return;
        const block = this;
        const previous = this.previous;
        const mode = block.getFieldValue("MODE");
        this.previous = mode;
        if (previous == null) return;
        const delete_shadow = () => {
            if (block.getInput("VALUE").connection.isConnected() && !block.getInput("VALUE").connection.targetBlock().isShadow_) {
                block.getInput("VALUE").connection.disconnect();
            }
            if (block.getInput("VALUE").connection.isConnected() && block.getInput("VALUE").connection.targetBlock().isShadow_) {
                block.getInput("VALUE").connection.targetBlock().dispose(false, false);
            }
        }
        if (previous !== mode) {
            delete_shadow();
            if (mode === "degrees_counted") {
                block.getInput("VALUE").setCheck("Number");
            }
            else if (mode === "stop_action") {
                block.getInput("VALUE").setCheck(["String", "spike_stop_action"]);
            }
            else if (mode === "stall_detection") {
                block.getInput("VALUE").setCheck("Boolean");
            } else {
                block.getInput("VALUE").setCheck(null);
            }
        }
        if (!block.getInput("VALUE").connection.isConnected()) {
            let connectBlock;
            if (mode === "degrees_counted") {
                connectBlock = block.workspace.newBlock("math_number");
                connectBlock.setFieldValue("0", "NUM");
            } else if (mode === "stop_action") {
                connectBlock = block.workspace.newBlock("spike_type_ACTION");
            } else if (mode === "stall_detection") {
                connectBlock = block.workspace.newBlock("logic_boolean");
                connectBlock.setFieldValue("TRUE", "BOOL");
            } else {
                connectBlock = block.workspace.newBlock("logic_null");
            }
            connectBlock.setShadow(true);
            connectBlock.initSvg();
            connectBlock.render();
            block.getInput("VALUE").connection.connect(connectBlock.outputConnection);
        }
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    const mode = block.getFieldValue("MODE");
    const value = generator.valueToCode(block, 'VALUE', Order.ATOMIC) || "0";
    return `${spikeMotor}.set_${mode}(${value})\n`;
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
});

defineBlock("spike_motor_was", {
    init: function () {
        this.appendValueInput("PORT")
            .appendField("Wurde der Motor")
        this.appendDummyInput()
            .appendField(new FieldDropdown([
                ["blockiert", "stalled"],
                ["unterbrochen", "interrupted"],
            ]), "MODE")
            .appendField("?");
        this.setInputsInline(true);
        this.setOutput(true, "Boolean");
        this.setColour("#d0593e");
        this.setTooltip("Gibt zurück, ob der Motor am angegebenen Port seit der letzten Abfrage blockiert oder unterbrochen wurde.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const spikeMotor = getMotor(block, "PORT", generator);
    const mode = block.getFieldValue("MODE");
    return [`${spikeMotor}.was_${mode}()`, Order.FUNCTION_CALL];
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
});



export default Category("Spike: Motor", "#d0593e", [
    buildBlock("spike_motor_start", { port_shadow: "A", mode: "speed", value_shadow: "n100_100_5" }),
    buildBlock("spike_motor_start", { port_shadow: "A", mode: "power", value_shadow: "n100_100_5" }),
    buildBlock("spike_motor_stop", { port_shadow: "A" }),
    buildBlock("spike_motor_run_to_position", { port_shadow: "A", direction: "shortest path", value_shadow: "0_100_5", angle_shadow: 0 }),
    buildBlock("spike_motor_run_to_degrees_counted", { port_shadow: "A", value_shadow: "n100_100_5", amount_shadow: -180 }),
    buildBlock("spike_motor_run_for", { mode: "seconds", port_shadow: "A", value_shadow: "n100_100_5", amount_shadow: 5 }),
    buildBlock("spike_motor_run_for", { mode: "degrees", port_shadow: "A", value_shadow: "n100_100_5", amount_shadow: 90 }),
    buildBlock("spike_motor_run_for", { mode: "rotations", port_shadow: "A", value_shadow: "0_100_5", amount_shadow: 1 }),
    buildBlock("spike_motor_get", { port_shadow: "A", mode: "speed" }),
    buildBlock("spike_motor_get", { port_shadow: "A", mode: "position" }),
    buildBlock("spike_motor_was", { port_shadow: "A", mode: "stalled" }),
    buildBlock("spike_motor_set", { port_shadow: "A", mode: "degrees_counted" }),
]);
