import { onlyBlocks } from "../blockly_setup";
import { libraryCategoriesList, providedCategories, registerToolbox } from "../toolbox";

registerToolbox("library", libraryCategoriesList);
registerToolbox("off", []);
registerToolbox("easy", onlyBlocks([
    providedCategories.logic,
    providedCategories.controls,
    providedCategories.math,
    providedCategories.text,
], "math_number",
    "text",
    "controls_if",
    "controls_whileUntil",
    "controls_for",
    "controls_flow_statements",
    "controls_repeat_ext",
    "controls_repeat",
    "math_arithmetic",
    "math_single",
    "text_print",
    "text_prompt_ext",
    "logic_compare",
    "logic_operation",
    "logic_negate",
    "logic_boolean",
));