import * as Blockly from "blockly/core";
import { pythonGenerator } from "blockly/python";
import { useEffect } from "react";
import { BlocklyWorkspace } from "react-blockly";

import * as localeDE from "blockly/msg/de";

Blockly.setLocale(localeDE);

export function BlocklyComponent({
    json,
    setJson,
    setPythonCode,
    toolbox,
    workspace,
    setWorkspace,
    ...other
}) {
    const workspaceConfiguration = {
        toolbox: toolbox,
        collapse: true,
        comments: true,
        disable: true,
        maxBlocks: Infinity,
        trashcan: true,
        horizontalLayout: false,
        toolboxPosition: 'start',
        css: true,
        // media: '/src/media/',
        rtl: false,
        scrollbars: true,
        sounds: true,
        oneBasedIndex: true,
        grid: {
            spacing: 20,
            length: 1,
            colour: '#ccc',
            snap: true
        },
        zoom: {
            controls: true,
            wheel: true,
            startScale: 1,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2
        }
    };

    function workspaceDidChange(workspace) {
        const code = pythonGenerator.workspaceToCode(workspace);
        setPythonCode(code);
    }

    useEffect(() => {
        console.log(22222)
        if (workspace == null) {
            return;
        }
        const current = Blockly.serialization.workspaces.save(workspace);
        console.log("current", current, "json", json, "same", JSON.stringify(json) === JSON.stringify(current));
        if (JSON.stringify(json) === JSON.stringify(current)) {
            console.log("ever happen?")
            return;
        }
        Blockly.serialization.workspaces.load(json, workspace);
        // setJson(json);
    }, [json, workspace]);

    useEffect(() => {
        if (workspace == null) {
            return;
        }
        workspace.updateToolbox(toolbox);
    }, [toolbox, workspace]);

    function onInject(workspace) {
        workspace.addChangeListener(Blockly.Events.disableOrphans);
        workspaceDidChange(workspace);

        const blocklyDiv = document.querySelector(".App-body > .injectionDiv");
        const blocklyArea = document.querySelector(".App-body");
        const onresize = function (e) {
            // Compute the absolute coordinates and dimensions of blocklyArea.
            let element = blocklyArea;
            let x = 0;
            let y = 0;
            do {
                x += element.offsetLeft;
                y += element.offsetTop;
                element = element.offsetParent;
            } while (element);
            // Position blocklyDiv over blocklyArea.
            blocklyDiv.style.left = x + 'px';
            blocklyDiv.style.top = y + 'px';
            blocklyDiv.style.width = blocklyArea.offsetWidth + 'px';
            blocklyDiv.style.height = blocklyArea.offsetHeight + 'px';
            blocklyDiv.style.position = 'absolute';
            Blockly.svgResize(workspace);
        };
        window.addEventListener('resize', onresize, false);
        onresize();

        setWorkspace(workspace);
    }

    return (
        <BlocklyWorkspace
            toolboxConfiguration={toolbox}
            className="App-body"
            workspaceConfiguration={workspaceConfiguration}
            onWorkspaceChange={workspaceDidChange}
            onJsonChange={(njson) => {
                if (workspace?.isDragging?.()) {
                    return;
                };
                console.log("json", njson);
                setJson(njson);
            }}
            onInject={onInject}
            {...other}
        />
    );
}