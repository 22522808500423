import { FieldAngle, registerFieldAngle } from "@blockly/field-angle";
import { FieldSlider } from '@blockly/field-slider';
import { FieldDropdown } from "blockly";
import { Order } from "blockly/python";
import { defineBlock } from "../../blockly_setup";
import { getPortSelector } from "./utils";

registerFieldAngle();
defineBlock('spike_type_port', {
    init() {
        this.appendDummyInput().appendField(getPortSelector(), "PORT");
        this.setOutput(true, ["spike_PORT"]);
        this.setColour("#5ba58c");
        this.setTooltip("");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [block.getFieldValue('PORT').toUpperCase(), Order.ATOMIC];
});

defineBlock("spike_type_GESTURE", {
    init() {
        this.appendDummyInput().appendField(new FieldDropdown([
            ["Schütteln", "shaken"],
            ["Tippen", "tapped"],
            ["Doppeltippen", "doubletapped"],
            ["Fallen", "falling"],
            ["keine", "none"]
        ]), "GESTURE")
        this.setOutput(true, "String");
        this.setColour("#5ba58c");
        this.setTooltip("Kann ersetzt werden durch: 'shaken', 'tapped', 'doubletapped', 'falling', 'none'");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [`"${block.getFieldValue('GESTURE')}"`, Order.ATOMIC];
});

defineBlock("spike_type_ACTION", {
    init() {
        this.appendDummyInput().appendField(new FieldDropdown([
            ["auslaufen lassen", "coast"],
            ["bremsen", "brake"],
            ["halten", "hold"],
        ]), "GESTURE")
        this.setOutput(true, "String");
        this.setColour("#5ba58c");
        this.setTooltip("Kann ersetzt werden durch: 'coast', 'brake', 'hold'");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [`"${block.getFieldValue('GESTURE')}"`, Order.ATOMIC];
});

export const block_color = defineBlock("spike_type_color", {
    init() {
        this.appendDummyInput()
            .appendField(new FieldDropdown([
                ["schwarz", "black"],
                ["violett", "violet"],
                ["blau", "blue"],
                ["türkis", "cyan"],
                ["grün", "green"],
                ["gelb", "yellow"],
                ["rot", "red"],
                ["weiß", "white"],
                ["andere", "other"],
            ]), "COLOR");
        this.setOutput(true, "String");
        this.setColour("#5ba58c");
        this.setTooltip("");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    const color = block.getFieldValue("COLOR");
    return [`'${color}'`, Order.ATOMIC];
}, function (blockData, { ...opts }) {
    if (opts.color) {
        blockData.fields.COLOR = opts.color;
    }
})

defineBlock("spike_ignore", {
    init() {
        this.appendValueInput("VALUE").appendField("");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    },
    onchange: function (e) {
        if (e.type !== "move") return;
        if (this.childBlocks_.length === 0) {
            this.workspace.getBlockById(this.id).dispose(true, false);
            this.setWarningText("Dieser Block benötigt einen Wert, der ignoriert wird.");
        }
    }

}, function (block, generator) {
    return generator.valueToCode(block, "VALUE", Order.NONE) + "\n";
});

defineBlock("spike_type_slider_0_100_1", {
    init: function () {
        this.appendDummyInput()
            .appendField(new FieldSlider(75, 0, 100, 1), "PERCENT")
        this.setInputsInline(true)
        this.setOutput(true, "Number")
        this.setColour("#5b67a5");
        this.setTooltip();
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [block.getFieldValue("PERCENT"), Order.ATOMIC];
});

defineBlock("spike_type_slider_0_100_5", {
    init: function () {
        this.appendDummyInput()
            .appendField(new FieldSlider(75, 0, 100, 5), "PERCENT")
        this.setInputsInline(true)
        this.setOutput(true, "Number")
        this.setColour("#5b67a5");
        this.setTooltip();
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [block.getFieldValue("PERCENT"), Order.ATOMIC];
});

defineBlock("spike_type_slider_n100_100_1", {
    init: function () {
        this.appendDummyInput()
            .appendField(new FieldSlider(75, -100, 100, 1), "PERCENT")
        this.setInputsInline(true)
        this.setOutput(true, "Number")
        this.setColour("#5b67a5");
        this.setTooltip();
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [block.getFieldValue("PERCENT"), Order.ATOMIC];
});
defineBlock("spike_type_slider_n100_100_5", {
    init: function () {
        this.appendDummyInput()
            .appendField(new FieldSlider(75, -100, 100, 5), "PERCENT")
        this.setInputsInline(true)
        this.setOutput(true, "Number")
        this.setColour("#5b67a5");
        this.setTooltip();
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [block.getFieldValue("PERCENT"), Order.ATOMIC];
});

defineBlock("spike_type_angle", {
    init: function () {
        this.appendDummyInput()
            .appendField(new FieldAngle(0, null, {
                offset: 90,
                clockwise: true,
            }), "ANGLE")
        this.setInputsInline(true)
        this.setOutput(true, "Number")
        this.setColour("#5b67a5");
        this.setTooltip();
        this.setHelpUrl("");
    }
}, function (block, generator) {
    return [block.getFieldValue("ANGLE"), Order.ATOMIC];
});
