export const emptyWorkspace = {};

export const loadingWorkspace = {
    "blocks": {
        "languageVersion": 0,
        "blocks": [
            {
                "type": "basic_loading",
                "x": 210,
                "y": 30,
                "deletable": false,
                "movable": false,
                "editable": false,
            }
        ]
    }
};

export const defaultWorkspace = {
    "blocks": {
        "languageVersion": 0,
        "blocks": [
            {
                "type": "basic_start",
                "x": 210,
                "y": 30,
                "deletable": false,
                "movable": false,
                "editable": false,
            }
        ]
    }
};