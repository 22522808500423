import colorCategory from "./color";
import forceCategory from "./force";
import gyroCategory from "./gyro";
import motorCategory from "./motor";
import "./types";

import { Category } from "../../blockly_setup";
import { provideCategory } from "../../toolbox";

provideCategory('spike', Category("Spike", 135, [
    forceCategory,
    gyroCategory,
    motorCategory,
    colorCategory,
    // Category("Hidden", "#009460", [
    //     // Block("spike_force_get_newton", {}, {}),
    //     // Block("spike_force_get_percentage", {}, {}),
    //     // Block("spike_force_wait_pressed", {}, {}),
    //     // Block("spike_force_wait_released", {}, {}),
    //     Block("spike_port", { PORT: "A" }, {}),
    // ]),
]));
