import { FieldDropdown } from 'blockly';
import { Order } from 'blockly/python';
import { Category, defineBlock } from '../../blockly_setup';
import { buildBlock } from '../../toolboxManager';
import { blockBuilderPORT, getForceSensor } from './utils';


defineBlock('spike_force_is_pressed', {
    init: function () {
        this.appendValueInput('PORT')
            .setCheck(['String', 'spike_PORT'])
            .appendField('Drucksensor');
        this.appendDummyInput()
            .appendField('wird gedrückt');
        this.setInputsInline(true)
        this.setOutput(true, "Boolean");
        this.setTooltip('');
        this.setHelpUrl('');
        this.setColour(135);
    }
}, function (block, generator) {
    return [`${getForceSensor(block, "PORT", generator)}.is_pressed()`, Order.FUNCTION_CALL];
}, blockBuilderPORT);

defineBlock('spike_force_get_force', {
    init: function () {
        this.appendDummyInput()
            .appendField("Druck in")
            .appendField(new FieldDropdown([
                ['Prozent', 'percentage'],
                ['Newton', 'newton']
            ]), 'MODE');
        this.appendValueInput('PORT')
            .setCheck(['String', 'spike_PORT'])
            .appendField('von Drucksensor');
        this.setInputsInline(true)
        this.setOutput(true, "Number");
        this.setTooltip('');
        this.setHelpUrl('');
        this.setColour(135);
    }
}, function (block, generator) {
    return [`${getForceSensor(block, "PORT", generator)}.get_force_${block.getFieldValue("MODE")}()`, Order.FUNCTION_CALL];
}, function (blockData, { ...opts }) {
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
    blockBuilderPORT(blockData, opts);
});

defineBlock('spike_force_wait_until', {
    init: function () {
        this.appendValueInput('PORT')
            .setCheck(['String', 'spike_PORT'])
            .appendField('Warte bis Drucksensor');
        this.appendDummyInput()
            .appendField(new FieldDropdown([
                ['gedrückt', 'pressed'],
                ['losgelassen', 'released']
            ]), 'MODE')
            .appendField("wird");
        this.setInputsInline(true)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('');
        this.setHelpUrl('');
        this.setColour(135);
    }
}, function (block, generator) {
    return `${getForceSensor(block, "PORT", generator)}.wait_until_${block.getFieldValue("MODE")}()\n`;
}, function (blockData, { ...opts }) {
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
    blockBuilderPORT(blockData, opts);
});

export default Category("Spike: Drucksensor", 135, [
    buildBlock("spike_force_is_pressed", { port_shadow: "A" }),
    buildBlock("spike_force_get_force", { mode: "percentage", port_shadow: "A" }),
    buildBlock("spike_force_get_force", { mode: "newton", port_shadow: "A" }),
    buildBlock("spike_force_wait_until", { mode: "pressed", port_shadow: "A" }),
    buildBlock("spike_force_wait_until", { mode: "released", port_shadow: "A" }),
]);
