import { FieldDropdown } from 'blockly';
import { Order } from 'blockly/python';
import { Category, defineBlock } from '../../blockly_setup';
import { block_color } from './types';
import { blockBuilderPORT, getColorSensor, getTmpVar } from './utils';


export const block_get_color = defineBlock('spike_color_get_color', {
    init: function () {
        this.appendValueInput('PORT')
            .setCheck(['String', 'spike_PORT'])
            .appendField('Farbe von Farbsensor');
        this.setInputsInline(true)
        this.setOutput(true, "String");
        this.setTooltip('');
        this.setHelpUrl('');
        this.setColour(135);
    }
}, function (block, generator) {
    const tmpVar = getTmpVar(generator);
    return [`("other" if (${tmpVar} := ${getColorSensor(block, "PORT", generator)}.get_color()) is None else ${tmpVar}`, Order.NONE];
}, blockBuilderPORT);

export const block_get_light = defineBlock('spike_color_get_light', {
    init: function () {
        this.appendValueInput('PORT')
            .setCheck(['String', 'spike_PORT'])
            .appendField(new FieldDropdown([
                ['reflektiertes Licht', 'reflected'],
                ['Umgebungslicht', 'ambient'],
            ]), "MODE")
            .appendField('in %')
            .appendField('von Farbsensor');
        this.setInputsInline(true)
        this.setOutput(true, "Number");
        this.setTooltip('');
        this.setHelpUrl('');
        this.setColour(135);
    }
}, function (block, generator) {
    return [`${getColorSensor(block, "PORT", generator)}.get_${block.getFieldValue("MODE")}_light()`, Order.FUNCTION_CALL];
}, (blockData, { ...opts }) => {
    blockBuilderPORT(blockData, opts);
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
});


// defineBlock('spike_force_get_force', {
//     init: function () {
//         this.appendDummyInput()
//             .appendField("Druck in")
//             .appendField(new FieldDropdown([
//                 ['Prozent', 'percentage'],
//                 ['Newton', 'newton']
//             ]), 'MODE');
//         this.appendValueInput('PORT')
//             .setCheck(['String', 'spike_PORT'])
//             .appendField('von Drucksensor');
//         this.setInputsInline(true)
//         this.setOutput(true, "Number");
//         this.setTooltip('');
//         this.setHelpUrl('');
//         this.setColour(135);
//     }
// }, function (block, generator) {
//     return [`${getForceSensor(block, "PORT", generator)}.get_force_${block.getFieldValue("MODE")}()`, Order.FUNCTION_CALL];
// }, function (blockData, { ...opts }) {
//     if (opts.mode) {
//         blockData.fields.MODE = opts.mode;
//     }
//     blockBuilderPORT(blockData, opts);
// });

// defineBlock('spike_force_wait_until', {
//     init: function () {
//         this.appendValueInput('PORT')
//             .setCheck(['String', 'spike_PORT'])
//             .appendField('Warte bis Drucksensor');
//         this.appendDummyInput()
//             .appendField(new FieldDropdown([
//                 ['gedrückt', 'pressed'],
//                 ['losgelassen', 'released']
//             ]), 'MODE')
//             .appendField("wird");
//         this.setInputsInline(true)
//         this.setPreviousStatement(true, null);
//         this.setNextStatement(true, null);
//         this.setTooltip('');
//         this.setHelpUrl('');
//         this.setColour(135);
//     }
// }, function (block, generator) {
//     return `${getForceSensor(block, "PORT", generator)}.wait_until_${block.getFieldValue("MODE")}()\n`;
// }, function (blockData, { ...opts }) {
//     if (opts.mode) {
//         blockData.fields.MODE = opts.mode;
//     }
//     blockBuilderPORT(blockData, opts);
// });

export default Category("Spike: Farbsensor", 135, [
    block_color({ color: "black" }),
    block_get_color({ port_shadow: "A" }),
    block_get_light({ port_shadow: "A", mode: "reflected" }),
]);
