// import logo from './logo.png';
import React, { useEffect, useMemo, useState } from "react";
import './App.css';
import { CatToolbox, registerContextMenus } from "./blockly_setup";
import { BlocklyComponent } from './components/Blockly';
import "./customBlocks/all";
import "./customCodes/all";
import { hasCode, runCode } from './customCodes/codes_registry';
import "./customToolboxes/all";
import { defaultWorkspace, loadingWorkspace } from "./defaults";
import { Communicator } from "./server_com";
import { getRegisteredToolboxes, getToolbox, hasToolbox } from './toolbox';

window.emerg = () => {
  document.body.innerHTML = "";
  document.body.style.backgroundColor = "black";
  document.body.style.color = "white";
  document.body.style.fontFamily = "monospace";
  document.body.style.fontSize = "2em";
  document.body.style.textAlign = "center";
  document.body.style.paddingTop = "20vh";
  document.body.innerHTML = "Wait for reset...";
  console.log("Wait for reset...");
  localStorage.clear();
  setTimeout(() => {
    localStorage.clear();
    document.body.innerHTML = "Wait for reload...";
    console.log("Wait for reload...");
    window.location.reload();
  }, 2000);
}

window.addEventListener("keydown", (e) => {
  if ((e.key === "F5" || e.key === "f5") && e.ctrlKey) {
    window.emerg();
    e.preventDefault();
  }
});

function getTextWidth(element, text) {
  var tmp = document.createElement(element);
  tmp.style.visibility = "hidden";
  tmp.style.position = "absolute";
  tmp.style.whiteSpace = "nowrap";
  tmp.innerText = text;
  document.body.appendChild(tmp);
  var theWidth = tmp.getBoundingClientRect().width;
  window.aaaa = tmp;
  document.body.removeChild(tmp);
  return theWidth;
}

const colors = {
  number: "red",
  letter: "blue",
}

function colorCode(text) {
  const letters = text.split("");
  return <>
    {
      letters.map((letter, i) => {
        return <span className="App-colorCode-codeLetter" key={i} style={{ "--App-colorCode-codeLetter-color": colors["0123456789".includes(letter) ? "number" : "letter"] }}>{letter}</span>
      })
    }
  </>
}

function usePersistantState(key, defaultValue, override = undefined) {
  const loadedData = localStorage.getItem(key);
  let startingValue;
  if (loadedData === null) {
    startingValue = defaultValue;
  } else {
    try {
      startingValue = JSON.parse(loadedData);
    } catch (e) {
      startingValue = defaultValue;
    }
  }
  if (override !== undefined) {
    startingValue = override;
  }
  const [value, setValue] = useState(startingValue);
  localStorage.setItem(key, JSON.stringify(value));
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);
  return [value, setValue];
}


function str2bool(str) {
  return (str === "true") ? true : (str === "false" ? false : undefined)
}

let startedLoading = false;

const comm = new Communicator("http://127.0.0.1:5000");

function App() {
  const [message, setMessage] = useState("");
  const [msgtype, setMsgtype] = useState("success");

  function displayMessage(type, message) {
    setMsgtype(type);
    setMessage(message);
  }

  const queryParams = useMemo(() => new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  }), []);

  if (window.location.search) window.history.replaceState(null, null, window.location.origin);
  if (queryParams.emerg != null) {
    window.emerg();
  }

  const newToolbox = getRegisteredToolboxes().includes(queryParams.toolbox) ? getToolbox(queryParams.toolbox) : undefined;
  if (queryParams.toolbox && newToolbox === undefined) {
    alert(`Could not interprete URL params:\n - No toolbox: ${queryParams.toolbox}`)
  }
  const [toolbox, setToolbox] = usePersistantState("toolbox", CatToolbox(), newToolbox);
  const [pythonCode, setPythonCode] = useState("");
  const [isEnteringCode, setIsEnteringCode] = useState(false);
  const [isEnteringSaveCode, setIsEnteringSaveCode] = useState(false);
  const [showControls, setShowControls] = usePersistantState("showControls", true, str2bool(queryParams.showControls));
  const [showOutput, setShowOutput] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [allowDeconstruct, setAllowDeconstruct] = usePersistantState("allowDeconstruct", false, str2bool(queryParams.allowDeconstruct));

  const [backendAvailable, setBackendAvailable] = useState(null);

  const [json, setJson] = useState(loadingWorkspace);

  useEffect(() => {
    let valid = true;

    comm.ping().then(() => {
      if (!valid) return;
      setBackendAvailable(true);
    }).catch((err) => {
      console.error(err)
      if (!valid) return;
      setBackendAvailable(false);
    });
    return () => {
      valid = false;
    }
  });


  (() => {
    // if (json != {}) return;
    if (startedLoading) return;
    startedLoading = true;
    if (queryParams.load != null) {
      comm.loadJsonFile(queryParams.load).then((njson) => {
        console.log("online", njson)
        setJson(njson);
        displayMessage("success", `Loaded workspace: ${queryParams.load}`)
      }).catch((e) => {
        displayMessage("issue", `Failed to load: ${e}`);
        startedLoading = false;
      });
    } else if (str2bool(queryParams.cleanWorkspace) ? defaultWorkspace : undefined) {
      console.log("default")
      setJson(defaultWorkspace);
    } else {
      const jsonW = localStorage.getItem("workspace");
      const pjsonW = jsonW ? JSON.parse(jsonW) : null;
      console.log("local", pjsonW);
      setJson(pjsonW || defaultWorkspace);
    };
  })();

  useEffect(() => {
    if (json == null) return;
    if (workspace == null) return;
    localStorage.setItem("workspace", JSON.stringify(json));
  }, [json, workspace]);

  const [saveCode, setSaveCode] = useState(null);

  useEffect(() => {
    if (!backendAvailable) return;
    if (!workspace) return;
    // setSaveCode("XXXXX");
    comm.saveJsonFile(json).then((code) => {
      setSaveCode(code);
    }).catch((e) => {
      setSaveCode(null);
      displayMessage("issue", `Failed to save: ${e}`);
    });
  }, [json, workspace, backendAvailable]);

  registerContextMenus({ allowDeconstruct });

  useEffect(() => {
    const onKeyDownHandler = (e) => {
      if (e.key === "F1" || e.key === "f1" || (
        e.ctrlKey && e.key === "Enter"
      )) {
        setIsEnteringCode(true);
        e.preventDefault();
      }
      if (e.key === "F2" || e.key === "f2" || (
        e.ctrlKey && e.key === "l"
      )) {
        setIsEnteringSaveCode(true);
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", onKeyDownHandler);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
    };
  });

  useEffect(() => {
    if (isEnteringCode) {
      setMsgtype("info");
      setMessage("Enter:");
    } else if (message === "Enter:") {
      setMessage("");
    }
  }, [isEnteringCode, message]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>GSG Robots @ Maker Faire</h1>
        <div>
          <span onClick={() => setMessage("")} className={`App-message ${msgtype}`}>{message}</span>
          {
            isEnteringCode ? (
              <input type="text"
                style={{ width: getTextWidth("span", "Use Code") - 10 + "px" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (!hasCode(e.target.value)) {
                      displayMessage("issue", `No such code: ${e.target.value}`);
                    } else {
                      runCode(e.target.value, { toolbox, setAllowDeconstruct, json, workspace, displayMessage, setToolbox, setJson, runCode, setShowControls, setShowOutput });
                    }
                    e.target.value = "";
                    setIsEnteringCode(false);
                  }
                  if (e.key === "Escape") {
                    e.target.value = "";
                    setIsEnteringCode(false);
                    // setMessage("");
                  }
                }}
                onBlur={() => setIsEnteringCode(false)}
                autoFocus
                // placeholder='Use Code'
                autoComplete='off'
                autoCorrect='off' />
            ) : (
              showControls ? <button onClick={() => setIsEnteringCode(true)}>Use Code</button> : null
            )
          }
          {
            showControls ? (
              <>
                {(
                  showOutput ? <button onClick={() => setShowOutput(false)}>Show Editor</button> : <button onClick={() => setShowOutput(true)}>Show Output</button>
                )}
                <select onChange={(e) => {
                  if (e.target.value === ":") return;
                  if (!hasToolbox(e.target.value)) {
                    displayMessage("issue", `No such toolbox: ${e.target.value}`);
                    return;
                  }
                  setToolbox(getToolbox(e.target.value));
                  displayMessage("success", `Switched toolbox: ${e.target.value}`);
                  e.target.value = ":";
                }} defaultValue=":">
                  <option disabled value=":">Change toolbox</option>
                  {
                    getRegisteredToolboxes().map((key) => (<option key={key} value={key}>{key}</option>))
                  }
                </select>
                <button onClick={() => setJson(defaultWorkspace)}>Clear</button>
                <button onClick={() => setShowControls(false)}>X</button>
              </>
            ) : null
          }
          {
            backendAvailable ? (
              isEnteringSaveCode ? (
                <>
                  <input type="text"
                    style={{ width: "7ch" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (!e.target.value) return;
                        const before = json;
                        setJson(loadingWorkspace); // Clear workspace
                        setSaveCode("Loading...");
                        comm.loadJsonFile(e.target.value).then((njson) => {
                          try {
                            setJson(JSON.parse(njson));
                          } catch (er) {
                            setJson(before);
                            displayMessage("issue", `Failed to load: ${er}`);
                            console.error("Failed loading", e.target.value, er, njson);
                          }
                        }).catch((er) => {
                          setJson(before);
                          displayMessage("issue", `Failed to load: ${er}`);
                          console.error("Failed loading", e.target.value, er);
                        });
                        setIsEnteringSaveCode(false);
                      }
                      if (e.key === "Escape") {
                        e.target.value = "";
                        setIsEnteringSaveCode(false);
                      }
                    }}
                    onBlur={() => setIsEnteringSaveCode(false)}
                    autoFocus
                    placeholder={saveCode || null}
                    autoComplete='off'
                    autoCorrect='off'
                    className="pre-code" />
                </>
              ) : (
                <code className="App-saveCode" onClick={() => setIsEnteringSaveCode(true)}>{saveCode ? colorCode(saveCode) : "Saving..."}</code>
              )
            ) : backendAvailable === null ? "Connecting..." : "No connection"
          }
        </div>
      </header >
      {
        < BlocklyComponent
          setPythonCode={setPythonCode}
          setJson={setJson}
          toolbox={toolbox}
          json={json}
          setWorkspace={setWorkspace}
          workspace={workspace}
        />
      }
      {
        showOutput && <pre className='App-body App-pythonCode' style={{ height: "90%", width: "90%", zIndex: 99999, position: "absolute", top: "5%", left: "5%" }}>{pythonCode}</pre>
      }
    </div >
    // {showOutput && <pre className='App-body App-pythonCode' style={{zIndex: 99999}}>{pythonCode}</pre>}
  );
}

export default App;
