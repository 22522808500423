import { FieldDropdown } from 'blockly';
import { Order } from 'blockly/python';
import { Category, defineBlock } from '../../blockly_setup';
import { buildBlock } from '../../toolboxManager';
import { blockBuilderGESTURE, getPrimeHub, getTmpVar } from './utils';

defineBlock("spike_gyro_was_gesture", {
    init: function () {
        this.appendValueInput("GESTURE")
            .appendField("Geste")
            .setCheck("String")
        this.appendDummyInput()
            .appendField("wurde erkannt")
        this.setInputsInline(true)
        this.setOutput(true, "Boolean")
        this.setColour(135);
        this.setTooltip("Überprüft, ob eine bestimmte Geste erkannt wurde, und gibt dies in boolschen Werten an.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    let spikePrimeHub = getPrimeHub(generator);
    let gesture = generator.valueToCode(block, 'GESTURE', Order.ATOMIC) || "None";
    let tmp = getTmpVar(generator);
    return [`${spikePrimeHub}.motion_sensor.was_gesture(None if (${tmp} := ${gesture}) == "none" else ${tmp})`, Order.FUNCTION_CALL];
}, blockBuilderGESTURE);

defineBlock("spike_gyro_wait_nout", {
    init: function () {
        this.appendDummyInput()
            .appendField("Warte auf neue")
            .appendField(new FieldDropdown([
                ["Orientierung", "orientation"],
                ["Geste", "gesture"],
            ]), "MODE")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("Wartet, bis eine neue Orientierung oder Geste erkannt wird.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    let spikePrimeHub = getPrimeHub(generator);
    let mode = block.getFieldValue("MODE");
    return `${spikePrimeHub}.motion_sensor.wait_for_new_${mode}()\n`;
});

defineBlock("spike_gyro_wait", {
    init: function () {
        this.appendDummyInput()
            .appendField("Warte auf neue")
            .appendField(new FieldDropdown([
                ["Orientierung", "orientation"],
                ["Geste", "gesture"],
            ]), "MODE")
            .appendField("und gebe sie zurück");
        this.setInputsInline(true);
        this.setOutput(true, "String");
        this.setColour(135);
        this.setTooltip("Wartet, bis eine neue Orientierung oder Geste erkannt wird und gibt diese zurück.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    let spikePrimeHub = getPrimeHub(generator);
    let mode = block.getFieldValue("MODE");
    return [`${spikePrimeHub}.motion_sensor.wait_for_new_${mode}()`, Order.FUNCTION_CALL];
}, function (blockData, { ...opts }) {

    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
    if (opts.no_output) {
        blockData.type = "spike_gyro_wait_nout";
    }
});

defineBlock("spike_gyro_last", {
    init: function () {
        this.appendDummyInput()
            .appendField("Gebe letzte erkannte")
            .appendField(new FieldDropdown([
                ["Orientierung", "orientation"],
                ["Geste", "gesture"],
            ]), "MODE")
            .appendField("zurück");
        this.setInputsInline(true);
        this.setOutput(true, "String");
        this.setColour(135);
        this.setTooltip("Gibt die letzte erkannte Orientierung oder Geste zurück.");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    let spikePrimeHub = getPrimeHub(generator);
    let mode = block.getFieldValue("MODE");
    return [`${spikePrimeHub}.motion_sensor.get_${mode}_angle()`, Order.FUNCTION_CALL];
}, function (blockData, { ...opts }) {
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
});

defineBlock("spike_gyro_angle", {
    init: function () {
        this.appendDummyInput()
            .appendField("Gebe aktuelle Drehung um")
            .appendField(new FieldDropdown([
                ["x-Achse (Nicken)", "pitch"],
                ["y-Achse (Rollen)", "roll"],
                ["z-Achse (Gieren)", "yaw"],
            ]), "MODE")
            .appendField("zurück");
        this.setInputsInline(true);
        this.setOutput(true, "Boolean");
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    let spikePrimeHub = getPrimeHub(generator);
    let mode = block.getFieldValue("MODE");
    return [`${spikePrimeHub}.motion_sensor.get_${mode}()`, Order.FUNCTION_CALL];
}, function (blockData, { ...opts }) {
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
});

defineBlock("spike_gyro_reset_yaw", {
    init: function () {
        this.appendDummyInput()
            .appendField("Setze Drehung um z-Achse (Gieren) zurück")
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
}, function (block, generator) {
    let spikePrimeHub = getPrimeHub(generator);
    return `${spikePrimeHub}.motion_sensor.reset_yaw_angle()\n`;
}, function (blockData, { ...opts }) { });

export default Category("Spike: Bewegungssensor", 135, [
    buildBlock("spike_gyro_was_gesture", { gesture_shadow: "none" }),
    buildBlock("spike_gyro_wait", { no_output: true, mode: "orientation" }),
    buildBlock("spike_gyro_wait", { mode: "gesture" }),
    buildBlock("spike_gyro_last", { mode: "orientation" }),
    buildBlock("spike_gyro_angle", { mode: "pitch" }),
    buildBlock("spike_gyro_reset_yaw", {}),
]); 
