import { FieldDropdown } from "blockly";
import { Order } from "blockly/python";
import { Block, Category, defineBlock, Input, Separator } from "../../blockly_setup";
import { registerToolbox } from "../../toolbox";
import { block_get_light } from "../spike/color";
import { block_motor_start } from "../spike/motor";
import { blockBuilderPORT, getColorSensor, getMotor } from "../spike/utils";
import { importModulePart } from "../utils";

const borderValue = 0.5;

const block_is_rather = defineBlock("minis:line_follower_is_rather", {
    init: function () {
        this.appendValueInput("PORT")
            .appendField("Lichtsensor")
            .setCheck(["String", "spike_PORT"]);
        this.appendDummyInput()
            .appendField("sieht überwiegend")
            .appendField(new FieldDropdown([
                ["weiß", ">"],
                ["schwarz", "<"],
            ]), "MODE");
        this.setOutput(true, "Boolean")
        this.setColour(155);
        this.setTooltip("");
        this.setHelpUrl("");
    },
    deconstruct(serialized_this) {
        return Block("logic_compare", {
            OP: "GT"
        }, {
            A: Input(null, Block("spike_color_get_light", {
                MODE: "reflected",
            }, {
                PORT: serialized_this.inputs?.PORT,
            })),
            B: Input(null, Block("math_number", {
                NUM: 50
            }, {})),
        });
    }
}, function (block, generator) {
    const spikeColorSensor = getColorSensor(block, "PORT", generator);
    const mode = block.getFieldValue("MODE");
    return [`${spikeColorSensor}.get_reflected_light() ${mode} ${borderValue * 100}`, Order.NONE];
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts);
    if (opts.mode) {
        blockData.fields.MODE = opts.mode;
    }
});

const block_while_infinite = defineBlock("minis:line_follower_while_infinite", {
    init: function () {
        this.appendDummyInput()
            .appendField("Mache dauerhaft");
        this.appendStatementInput("DO")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#5ba55b");
        this.setTooltip("");
        this.setHelpUrl("");
    },
    warnUntil: 0,
    warnBrother: undefined,
    onchange: function (e) {
        if (this.getNextBlock() != null) {
            this.warnUntil = Date.now() + 10000;
            this.warnBrother = this.getNextBlock();
            this.setWarningText("Alle diesem Block folgenden Blöcke würden nicht ausgeführt, da dieser Block unendlich ist.\nUm Logikfehler zu vermeiden, wurde die Verbindung zu diesen getrennt.");
            this.getNextBlock().setWarningText("Dieser und alle folgenden Blöcke würden nicht ausgeführt, da der vorhergehende Block unendlich ist.\nUm Logikfehler zu vermeiden, wurde die Verbindung zu diesem getrennt.")
            this.nextConnection.disconnect();
        }
        if (this.warnBrother && Date.now() > this.warnUntil) {
            this.setWarningText(null);
            if (!this.warnBrother.disposed)
                this.warnBrother.setWarningText(null);
            this.warnBrother = undefined;
        }
    },
    deconstruct(serialized_this) {
        const oths = {};
        if (serialized_this.inputs?.DO) {
            oths.DO = serialized_this.inputs.DO;
        }
        return Block("controls_whileUntil", {
            MODE: "WHILE"
        }, {
            BOOL: Input(null, Block("logic_boolean", { BOOL: "TRUE" }, {})),
            ...oths
        }, null, serialized_this.next);
    }
}, function (block, generator) {
    const statement = generator.statementToCode(block, "DO");
    const sleep = importModulePart("time", "sleep", generator);
    return `while True:\n${statement}\n${generator.INDENT}${sleep}(0.2)\n`;
}, function (blockData, { ...opts }) { });

const block_start_motors = defineBlock("minis:line_follower_start", {
    init: function () {
        this.appendValueInput("PORT1")
            .appendField("Starte die Motoren")
            .setCheck(["String", "spike_PORT"]);
        this.appendValueInput("PORT2")
            .setCheck(["String", "spike_PORT"]);
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("");
        this.setHelpUrl("");
    },
    deconstruct(serialized_this) {
        return Block("spike_motor_start", {
            MODE: "speed"
        }, {
            PORT: serialized_this.inputs?.PORT1,
            VALUE: Input(Block("spike_type_slider_n100_100_5", { PERCENT: 30 }, {})),
        },
            null, Input(null, Block("spike_motor_start", {
                MODE: "speed"
            }, {
                PORT: serialized_this.inputs?.PORT2,
                VALUE: Input(Block("spike_type_slider_n100_100_5", { PERCENT: 30 }, {}))
            }, null, serialized_this.next)
            )
        );
    }
}, function (block, generator) {
    const spikeMotor1 = getMotor(block, "PORT1", generator);
    const spikeMotor2 = getMotor(block, "PORT2", generator);
    return `${spikeMotor1}.start(30)\n${spikeMotor2}.start(30)\n`;
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts, "PORT1");
    blockBuilderPORT(blockData, opts, "PORT2");
});
const block_steer_motors = defineBlock("minis:line_follower_steer", {
    init: function () {
        this.appendValueInput("PORT1")
            .appendField("Lenke Motoren", "txt")
            .setCheck(["String", "spike_PORT"]);
        this.appendValueInput("PORT2")
            .setCheck(["String", "spike_PORT"]);
        this.appendDummyInput()
            .appendField(new FieldDropdown([
                ["geradeaus", "straight"],
                ["nach links", "left"],
                ["nach rechts", "right"],
            ]), "DIRECTION");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour("#d0593e");
        this.setTooltip("");
        this.setHelpUrl("");
    },
    deconstruct(serialized_this) {
        return Block("spike_motor_start", {
            MODE: "speed"
        }, {
            PORT: serialized_this.inputs?.PORT1,
            VALUE: Input(Block("spike_type_slider_n100_100_5", { PERCENT: serialized_this.fields.DIRECTION === "left" ? 20 : 30 }, {}))
        }, null, Input(null, Block("spike_motor_start", {
            MODE: "speed"
        }, {
            PORT: serialized_this.inputs?.PORT2,
            VALUE: Input(Block("spike_type_slider_n100_100_5", { PERCENT: serialized_this.fields.DIRECTION === "left" ? 30 : 20 }, {}))
        }, null, serialized_this.next)
        ));
    }
}, function (block, generator) {
    const spikeMotor1 = getMotor(block, "PORT1", generator);
    const spikeMotor2 = getMotor(block, "PORT2", generator);
    const direction = block.getFieldValue("DIRECTION");
    let speed1 = 30;
    let speed2 = 30;
    if (direction === "left") {
        speed1 = 20;
    } else if (direction === "right") {
        speed2 = 20;
    }
    return `${spikeMotor1}.run(${speed1})\n${spikeMotor2}.run(${speed2})\n`;
}, function (blockData, { ...opts }) {
    blockBuilderPORT(blockData, opts, "PORT1");
    blockBuilderPORT(blockData, opts, "PORT2");
    if (opts.direction) {
        blockData.fields.DIRECTION = opts.direction;
    }
});

registerToolbox("minis:line_follower:l1", [
    Category("Alle Blöcke", 0, [
        block_while_infinite({}),
        Block("controls_if", {}, {}, { elseIfCount: 1 }),
        Block("controls_if", {}, {}, { hasElse: true }),
        block_is_rather({ port_shadow: "A", mode: ">" }),
        block_start_motors({ port1_shadow: "E", port2_shadow: "F" }),
        block_steer_motors({ port1_shadow: "E", port2_shadow: "F", direction: "left" }),
    ]),
    Separator(),
    Category("Logik", "#5b80a5", [
        block_while_infinite({}),
        Block("controls_if", {}, {}, { elseIfCount: 1 }),
        Block("controls_if", {}, {}, { hasElse: true }),
    ]),
    Category("Lichtsensor", 155, [
        block_is_rather({ port_shadow: "A", mode: ">" }),
    ]),
    Category("Motoren", "#d0593e", [
        block_start_motors({ port1_shadow: "E", port2_shadow: "F" }),
        block_steer_motors({ port1_shadow: "E", port2_shadow: "F", direction: "left" }),
    ]),
])


registerToolbox("minis:line_follower:l2", [
    Category("Alle Blöcke", 0, [
        Block("controls_whileUntil", { MODE: "WHILE" }, {}, {}),
        Block("controls_if", {}, {}, {}),
        Block("logic_compare", { OP: "GT" }, {}, {}),
        Block("logic_boolean", { BOOL: "TRUE" }, {}, {}),
        block_get_light({ port_shadow: "A", mode: "reflected" }),
        Block("math_number", { NUM: 0 }, {}, {}),
        block_motor_start({ port_shadow: "E", value: "n100_100_5=30" }),
        block_motor_start({ port_shadow: "F", value: "n100_100_5=30" }),
    ]),
    Separator(),
    Category("Logik", "#5b80a5", [
        Block("controls_whileUntil", { MODE: "WHILE" }, {}, {}),
        Block("controls_if", {}, {}, {}),
        Block("logic_compare", { OP: "GT" }, {}, {}),
        Block("logic_boolean", { BOOL: "TRUE" }, {}, {}),
    ]),
    Category("Mathematik", "#5b67a5", [
        Block("math_number", { NUM: 0 }, {}, {}),
    ]),
    Category("Lichtsensor", 155, [
        block_get_light({ port_shadow: "A", mode: "reflected" }),
    ]),
    Category("Motoren", "#d0593e", [
        block_motor_start({ port_shadow: "E", value_shadow: "n100_100_5=30", mode: "speed" }),
        block_motor_start({ port_shadow: "F", value_shadow: "n100_100_5=30", mode: "speed" }),
    ]),
])
