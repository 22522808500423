import { getToolbox, hasToolbox } from "../toolbox";
import { registerCode } from "./codes_registry";

export function registerToolboxCode(id, toolbox) {
    registerCode(id, function ({ displayMessage, setToolbox }) {
        if (!hasToolbox(toolbox)) {
            displayMessage("issue", `No such toolbox: ${toolbox}`, 3000);
            return;
        }
        setToolbox(getToolbox(toolbox));
        displayMessage("success", `Switched toolbox: ${toolbox}`, 3000);
    });
}