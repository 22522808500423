import { serialization } from "blockly";

export class Communicator {
    constructor(backend) {
        this.backend = backend;
    }

    async saveJsonFile(json) {
        const resp = await fetch(this.backend + "/file", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(json)
        });
        if (!resp.ok) {
            throw new Error(resp.statusText);
        }
        const code = await resp.text();
        return code;
    }

    loadJsonFile(code) {
        return new Promise((resolve, reject) => {
            fetch(this.backend + "/file/" + code, {
                method: "GET"
            }).then(resp => {
                if (resp.status === "404") {
                    reject("No such save");
                }
                if (!resp.ok) {
                    reject(`Error: ${resp.status} ${resp.statusText}`);
                }
                resp.text().then((text) => {
                    resolve(text)
                }).catch(() => {
                    reject("Huh? I have no idea how this could ever happen!!!")
                })
            })
                .catch(e => {
                    reject("Could not fetch.")
                })
        });
    }

    ping() {
        return new Promise((resolve, reject) => {
            fetch(this.backend + "/ping", {
                method: "GET"
            }).then(async resp => {
                const body = await resp.text()
                if (resp.ok && body === "pong") {
                    resolve();
                } else {
                    console.warn(`PING Response: ${resp.status} ${body}`)
                    reject("Invalid response");
                }
            }).catch(err => {
                reject(err);
            });

        });
    }
}

export async function saveWorkspace(backendServer, workspace) {
    const json = serialization.workspaces.save(workspace);
    const communicator = new Communicator(backendServer);
    const code = await communicator.saveJsonFile(json);
    return code;
}


export async function loadWorkspace(backendServer, workspace, code) {
    const communicator = new Communicator(backendServer);
    const json = await communicator.loadJsonFile(code);
    serialization.workspaces.load(json, workspace, { recordUndo: true });
}
