import { defineBlock } from "../blockly_setup";


defineBlock("basic_start", {
    init: function () {
        this.appendDummyInput()
            .appendField("Programm")
        this.setColour(60);
        this.setTooltip("");
        this.setNextStatement(true, null);
        this.setHelpUrl("");
        this.setDeletable(false);
        this.setMovable(false);
        this.setEditable(false);
        this.contextMenu = false;
    },
}, function (block, generator) {
    return "# ----- PROGRAM START ----- #\n\n";
});


defineBlock("basic_loading", {
    init: function () {
        this.appendDummyInput()
            .appendField("Programm wird geladen...")
        this.setColour(60);
        this.setTooltip("");
        // this.setNextStatement(false, null);
        this.setHelpUrl("");
        this.setDeletable(false);
        this.setMovable(false);
        this.setEditable(false);
        this.contextMenu = false;
    },
}, function (block, generator) {
    return "# Loading...";
});

