import { Separator } from "../blockly_setup";
import { providedCategories, registerToolbox } from "../toolbox";


registerToolbox("spike", [
    providedCategories.spike,
    Separator(),
    providedCategories.logic,
    providedCategories.controls,
    providedCategories.math,
    providedCategories.text,
    providedCategories.lists,
    Separator(),
    providedCategories.variables,
    providedCategories.procedures,
]);
