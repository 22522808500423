import { utils } from "blockly";

const blocks = {};

export function addBlockBuilder(block, func) {
    blocks[block] = func;
}

export function buildBlock(id, { ...options }) {
    const blockData = {
        fields: {},
        inputs: {},
        extraState: undefined,
    };
    blocks[id](blockData, { ...options });
    return {
        kind: "block",
        type: id,
        id: utils.idGenerator.genUid(),
        ...blockData,
    };
}
