import * as Blockly from 'blockly/core';
import { pythonGenerator } from 'blockly/python';
import { addBlockBuilder, buildBlock } from './toolboxManager';


export function transformWorkspaceBlocks(json, check, transformer) {
    if (!json?.blocks?.blocks) {
        return json;
    }
    const replace = (obj) => {
        if (check(obj)) {
            obj = transformer(obj);
        }
        if (obj.next) {
            if (obj.next.block) {
                obj.next.block = replace(obj.next.block);
            }
            if (obj.next.shadow) {
                obj.next.shadow = replace(obj.next.shadow);
            }
        }
        if (obj.inputs) {
            for (const [key, input] of Object.entries(obj.inputs)) {
                if (!input) {
                    continue;
                }
                if (input.block) {
                    input.block = replace(input.block);
                }
                if (input.shadow) {
                    input.shadow = replace(input.shadow);
                }
                obj.inputs[key] = input;
            };
        }
        return obj;
    };
    json.blocks.blocks = json.blocks.blocks.map(topBlock => replace(topBlock));
    return json;
}


export function transformToolboxBlocks(json, check, transformer) {
    if (!json?.contents) {
        return json;
    }
    const replace = (obj) => {
        if (obj.kind === "block") {
            if (check(obj)) {
                obj = transformer(obj);
            }
            if (obj.next) {
                if (obj.next.block) {
                    obj.next.block = replace(obj.next.block);
                }
                if (obj.next.shadow) {
                    obj.next.shadow = replace(obj.next.shadow);
                }
            }
            if (obj.inputs) {
                for (const [key, input] of Object.entries(obj.inputs)) {
                    if (!input) {
                        continue;
                    }
                    if (input.block) {
                        input.block = replace(input.block);
                    }
                    if (input.shadow) {
                        input.shadow = replace(input.shadow);
                    }
                    obj.inputs[key] = input;
                };
            }
            return obj;
        } else if (obj.kind === "category") {
            obj.contents = obj.contents.map(v => replace(v));
        }
        return obj;
    };
    json.contents = json.contents.map(topBlock => replace(topBlock));
    return json;
}

let _ContextMenus_allowDeconstruct = false;

export function registerContextMenus({ allowDeconstruct }) {
    _ContextMenus_allowDeconstruct = allowDeconstruct;
}
Blockly.ContextMenuRegistry.registry.register({
    displayText: 'Block zerlegen',
    preconditionFn: function (scope) {
        if (!_ContextMenus_allowDeconstruct) return 'hidden';
        return (scope.block.deconstruct != null) ? 'enabled' : 'disabled';
    },
    callback: function (scope) {
        const serialized = Blockly.serialization.workspaces.save(scope.block.workspace);
        const transformed = transformWorkspaceBlocks(serialized, obj => obj.id === scope.block.id, scope.block.deconstruct);
        Blockly.serialization.workspaces.load(transformed, scope.block.workspace, { recordUndo: true });
    },
    scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
    id: 'deconstruct_block',
    weight: 100,
});
Blockly.ContextMenuRegistry.registry.register({
    displayText: 'Alle Blöcke zerlegen',
    preconditionFn: function (scope) {
        if (!_ContextMenus_allowDeconstruct) return 'hidden';
        return scope.workspace.getAllBlocks().some(block => block.deconstruct != null) ? 'enabled' : 'disabled';
    },
    callback: function (scope) {
        const serialized = Blockly.serialization.workspaces.save(scope.workspace);
        const transformed = transformWorkspaceBlocks(serialized, obj => true, (serialized_this) => {
            return scope.workspace.getBlockById(serialized_this.id)?.deconstruct?.(serialized_this) || serialized_this;
        });
        Blockly.serialization.workspaces.load(transformed, scope.workspace, { recordUndo: true });
    },
    scopeType: Blockly.ContextMenuRegistry.ScopeType.WORKSPACE,
    id: 'deconstruct_all_blocks',
    weight: 100,
});


pythonGenerator.INDENT = "    ";

export function defineBlock(id, blockDefinition, pythonTranspiler, stateGenerator) {
    let dict = {};
    dict[id] = blockDefinition;
    Blockly.common.defineBlocks(dict);
    pythonGenerator.forBlock[id] = pythonTranspiler;
    addBlockBuilder(id, stateGenerator);
    return ({ ...opts }) => buildBlock(id, opts);
}

export function filterBlocks(blockFilter, categroyFilter, contents) {
    return contents.filter((item) => {
        if (item.kind === "block") {
            return blockFilter(item);
        }
        if (item.kind === "category") {
            return categroyFilter(item);
        }
        return true;
    }).map((item) => {
        if (item.kind === "category") {
            return {
                ...item,
                contents: filterBlocks(blockFilter, categroyFilter, item.contents),
            }
        }
        return item;
    });
}

export function withoutBlocks(contents, ...ids) {
    return filterBlocks((item) => {
        return !ids.includes(item.type);
    }, () => true, contents);
}

export function onlyBlocks(contents, ...ids) {
    return filterBlocks((item) => {
        return ids.includes(item.type);
    }, () => true, contents);
}

export function withoutCategories(contents, ...names) {
    return filterBlocks(() => true, (item) => {
        return !names.includes(item.name);
    }, contents);
}

export function onlyCategories(contents, ...names) {
    return filterBlocks(() => true, (item) => {
        return names.includes(item.name);
    }, contents);
}

export function Variable(name) {
    return {
        // kind: "variable",
        type: "",
        name,
    }
}

export function Input(shadow, block) {
    return {
        shadow,
        block,
    }
}

export function Block(id, fields, inputs, extraState, next) {
    const blk = {
        kind: "block",
        type: id,
        id: Blockly.utils.idGenerator.genUid(),
        fields,
        inputs,
    };
    if (extraState) {
        blk.extraState = extraState;
    }
    if (next) {
        blk.next = next;
    }
    return blk;
}

export function Separator() {
    return {
        kind: "sep",
    }
}

export function Category(name, colour, items) {
    return {
        kind: "category",
        name,
        colour,
        contents: items,
    }
}
export function CustomCategory(name, colour, custom) {
    return {
        kind: "category",
        name,
        colour,
        custom
    }
}

export function CatToolbox(...items) {
    return {
        kind: "categoryToolbox",
        contents: items,
    }
}

export function BloToolbox(...items) {
    return {
        kind: "flyoutToolbox",
        contents: items,
    }
}
