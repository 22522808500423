import { Order } from 'blockly/python';
import { defineConstant, importModulePart } from '../utils';
// import { FieldDropdown } from 'blockly/core';
import { FieldGridDropdown } from '@blockly/field-grid-dropdown';
import { Block, Input } from '../../blockly_setup';

export function getLimited(min, max, generator) {
    const addname = min.toString().replace("-", "n") + "_" + max.toString().replace("-", "n");
    return defineConstant(`lim_${addname}__`, `lambda x: min(max(x, ${min}), ${max})`, generator);
}

export function limit(value, min, max, generator) {
    return `${getLimited(min, max, generator)}(${value})`;
}

export function getForceSensor(block, port, generator) {
    const type = block.getInput(port).connection?.targetConnection.check;
    const code = generator.valueToCode(block, port, Order.NONE);
    const spikeForceSensor = importModulePart("spike", "ForceSensor", generator);
    if (type && (type.indexOf("spike_PORT") !== -1 || type === "spike_PORT")) {
        return defineConstant(`forcesensor_${code.toLowerCase()}`, `${spikeForceSensor}("${code}")`, generator);
    } else {
        return `${spikeForceSensor}(${code})`;
    }
}

export function getColorSensor(block, port, generator) {
    const type = block.getInput(port).connection?.targetConnection.check;
    const code = generator.valueToCode(block, port, Order.NONE);
    const spikeColorSensor = importModulePart("spike", "ColorSensor", generator);
    if (type && (type.indexOf("spike_PORT") !== -1 || type === "spike_PORT")) {
        return defineConstant(`colorsensor_${code.toLowerCase()}`, `${spikeColorSensor}("${code}")`, generator);
    } else {
        return `${spikeColorSensor}(${code})`;
    }
}

export function getMotor(block, port, generator) {
    const type = block.getInput(port).connection?.targetConnection.check;
    const code = generator.valueToCode(block, port, Order.NONE) || "'A'";
    const spikeMotor = importModulePart("spike", "Motor", generator);
    if (type && (type.indexOf("spike_PORT") !== -1 || type === "spike_PORT")) {
        return defineConstant(`motor_${code.toLowerCase()}`, `${spikeMotor}("${code}")`, generator);
    } else {
        return `${spikeMotor}(${code})`;
    }

}

export function getPrimeHub(generator) {
    const spikePrimeHub = importModulePart("spike", "PrimeHub", generator);
    return defineConstant(`hub`, `${spikePrimeHub}()`, generator);
}

export function getPortSelector() {
    return new FieldGridDropdown([["A", "A"], ["B", "B"], ["C", "C"], ["D", "D"], ["E", "E"], ["F", "F"]], null, {
        columns: 2,
    })
}

export function getTmpVar(generator) {
    generator._gsg_tmp_var = generator.provideFunction_("_", [], "");
    return generator._gsg_tmp_var;
}

export function blockBuilderPORT(blockData, { ...opts }, name = "PORT") {
    blockData.inputs[name] = Input();
    const shadnam = name.toLowerCase() + "_shadow";
    const blocknam = name.toLowerCase() + "_block";
    if (opts[shadnam]) {
        if (typeof opts[shadnam] === "string") {
            opts[shadnam] = Block("spike_type_port", { PORT: opts[shadnam] }, {});
        }
        blockData.inputs[name].shadow = opts[shadnam];
    }
    if (opts[blocknam]) {
        if (typeof opts[blocknam] === "string") {
            opts[blocknam] = Block("spike_type_port", { PORT: opts[blocknam] }, {});
        }
        blockData.inputs[name].block = opts[blocknam];
    }
}

export function blockBuilderVALUESlider(blockData, { ...opts }) {
    blockData.inputs.VALUE = Input();
    if (opts.value_shadow) {
        if (typeof opts.value_shadow === "string") {
            const parts = opts.value_shadow.split("=");
            opts.value_shadow = Block("spike_type_slider_" + parts[0], { PERCENT: parseInt(parts[1]) || 75 }, {});
        }
        blockData.inputs.VALUE.shadow = opts.value_shadow;
    }
    if (opts.value_block) {
        if (typeof opts.value_block === "string") {
            const parts = opts.value_shadow.split("=");
            opts.value_block = Block("spike_type_slider_" + opts.value_block, { PERCENT: parseInt(parts[1]) || 75 }, {});
        }
        blockData.inputs.VALUE.block = opts.value_block;
    }
}

export function blockBuilderANGLE(blockData, { ...opts }) {
    blockData.inputs.ANGLE = Input();
    if (opts.angle_shadow !== undefined) {
        if (typeof opts.angle_shadow === "number") {
            opts.angle_shadow = Block("spike_type_angle", { ANGLE: opts.angle_shadow }, {});
        }
        blockData.inputs.ANGLE.shadow = opts.angle_shadow;
    }
    if (opts.angle_block !== undefined) {
        if (typeof opts.angle_block === "number") {
            opts.angle_block = Block("spike_type_angle", { ANGLE: opts.angle_block }, {});
        }
        blockData.inputs.ANGLE.block = opts.angle_block;
    }
}

export function blockBuilderGESTURE(blockData, { ...opts }) {
    blockData.inputs.GESTURE = Input();
    if (opts.gesture_shadow) {
        if (typeof opts.gesture_shadow === "string") {
            opts.gesture_shadow = Block("spike_type_GESTURE", { GESTURE: opts.gesture_shadow }, {});
        }
        blockData.inputs.GESTURE.shadow = opts.gesture_shadow;
    }
    if (opts.gesture_block) {
        if (typeof opts.gesture_block === "string") {
            opts.gesture_shadow = Block("spike_type_GESTURE", { GESTURE: opts.gesture_shadow }, {});
        }
        blockData.inputs.GESTURE.block = opts.gesture_block;
    }
}
export function blockBuilderAMOUNT(blockData, { ...opts }) {
    blockData.inputs.AMOUNT = Input();
    if (opts.amount_shadow) {
        if (typeof opts.amount_shadow === "number") {
            opts.amount_shadow = Block("math_number", { NUM: opts.amount_shadow }, {});
        }
        blockData.inputs.AMOUNT.shadow = opts.amount_shadow;
    }
    if (opts.amount_block) {
        if (typeof opts.amount_block === "number") {
            opts.amount_block = Block("math_number", { NUM: opts.amount_block }, {});
        }
        blockData.inputs.AMOUNT.block = opts.amount_block;
    }
}